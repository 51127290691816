/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
// s

/* Component Imports */
import { Typography, Button } from "@material-ui/core";

/* Data Imports */
import AppConfig from "../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  megaContainer: {
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      padding: "0rem 0.5rem 1rem 0.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0rem 1.5rem 1rem 1.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0rem 3rem 1rem 3rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    /* XLG breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.lg + 48)]: {
      padding: "0rem 5rem 1rem 5rem",
    },
    /* Full HD breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: "0rem 23rem 1rem 23rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      padding: "0rem 36rem 1rem 36rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      padding: "0rem 72rem 1rem 72rem",
    },
    background: "#fafafa",
    margin: "0rem 0rem 6rem 0rem",
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0rem 3rem 0rem",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2rem 0rem 2rem 0rem",
    width: "100%",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    width: "100%",
  },
  heading: {
    color: "#0FCA56",
    // background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    fontWeight: "400",
    fontSize: "2.5rem",
    lineHeight: "3rem",
    margin: "0rem 0rem 0.5rem 0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.125rem",
      lineHeight: "3.1875rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.75rem",
      lineHeight: "2rem",
    },
  },
  subHeading: {
    margin: "0.5rem 0rem 0.5rem 0rem",
    width: "100%",
    color: "#202020",
    fontWeight: "400",
    fontSize: "1.125rem",
    lineHeight: "2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      lineHeight: "1.7rem",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    margin: "0.5rem 0rem 0rem 0rem",
  },
  primaryButton: {
    paddingLeft: "0rem",
    color: "#0FCA56",
    // background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    borderRadius: "0rem",
    "&:hover": {
      background: "-webkit-linear-gradient(180deg, #74378E 0%, #502761 100%)",
      "-webkit-background-clip": "text",
      textDecoration: "underline",
      textUnderlineOffset: "6px",
    },
    fontSize: "1.25rem",
    lineHeight: "1.5rem",
    fontWeight: 500,
    textTransform: "capitalize",
  },
  heavieHouseChapterOneIFrame: {
    margin: "1rem 0rem 0rem 0rem",
    width: "100%",
    borderRadius: "0.25rem",
    boxShadow:
      "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
  },
  heading2: {
    color: "#0FCA56",
    // background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    fontWeight: "400",
    fontSize: "2.5rem",
    lineHeight: "3rem",
    margin: "4rem 0rem 1rem 0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.125rem",
      lineHeight: "3.1875rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.75rem",
      lineHeight: "2rem",
    },
  },
}));

const SectionTwo = () => {
  const classes = useStyles();

  const mcRef = React.useRef(null);

  const [IFrameWidth, setIFrameWidth] = React.useState(560);
  const [IFrameHeight, setIFrameHeight] = React.useState(315);

  const videoReleaseEpochMilliseconds = 1669979700000; // This is the release date and time of the video in epoch milliseconds. Set it to UTC time if change is needed in release date and time. UTC time is 5 hours and 30 minutes behind IST - IST - 05:30. To make this process easier, use this website => https://www.epochconverter.com/
  const currentEpochMilliseconds = new Date().getTime();
  const isVideoReleased =
    currentEpochMilliseconds > videoReleaseEpochMilliseconds;

  React.useEffect(() => {
    const mc = window.getComputedStyle(mcRef.current, null);
    const computedPaddingLeft = parseFloat(mc.paddingLeft.split("px")[0]);
    const computedPaddingRight = parseFloat(mc.paddingRight.split("px")[0]);
    const computedTotalPadding = computedPaddingLeft + computedPaddingRight;
    const computedWidth = parseFloat(mc.width.split("px")[0]);
    const computedTotalUsableWidth = computedWidth - computedTotalPadding;
    const heightToUse = (computedTotalUsableWidth * 9) / 16;

    setIFrameWidth(computedTotalUsableWidth);
    setIFrameHeight(heightToUse);

    const hhc1ifresizer = () => {
      const mc = window.getComputedStyle(mcRef.current, null);
      const computedPaddingLeft = parseFloat(mc.paddingLeft.split("px")[0]);
      const computedPaddingRight = parseFloat(mc.paddingRight.split("px")[0]);
      const computedTotalPadding = computedPaddingLeft + computedPaddingRight;
      const computedWidth = parseFloat(mc.width.split("px")[0]);
      const computedTotalUsableWidth = computedWidth - computedTotalPadding;
      const heightToUse = (computedTotalUsableWidth * 9) / 16;

      setIFrameWidth(computedTotalUsableWidth);
      setIFrameHeight(heightToUse);
    };

    window.addEventListener("resize", hhc1ifresizer);

    return () => {
      window.removeEventListener("resize", hhc1ifresizer);
    };
  }, []);

  return (
    <React.Fragment>
      <div ref={mcRef} className={classes.megaContainer}>
        <div className={classes.sectionContainer}>
          {/* <AnimationOnScroll
            animateIn="animate__fadeIn"
            duration={2}
            animateOnce={true}
          > */}
          <div className={classes.text}>
            <Typography variant="h3" component="h3" className={classes.heading}>
              About us
            </Typography>
            <Typography
              variant="body1"
              component="span"
              className={classes.subHeading}
            >
              Sentient was created to develop all aspects of the real estate
              segment in a way that will inspire the coming generations to
              create sustainable and functional structures that nurture the
              earth and push the evolution of human society in a positive
              direction.
            </Typography>
            <div className={classes.buttons}>
              <Button
                href="/about-us"
                title="Know More"
                rel="noopener"
                className={classes.primaryButton}
                endIcon={
                  <img
                    src="/images/icons/misc/arrow_right_green.svg"
                    alt={AppConfig.imageAltText}
                    width={16}
                    height={16}
                    loading="eager"
                    referrerPolicy="no-referrer"
                  />
                }
              >
                Know More
              </Button>
            </div>
            {isVideoReleased ? (
              <React.Fragment>
                <Typography
                  variant="h3"
                  component="h3"
                  className={classes.heading2}
                >
                  Heavie House by Sentient
                </Typography>
                <iframe
                  className={classes.heavieHouseChapterOneIFrame}
                  width={IFrameWidth}
                  height={IFrameHeight}
                  src="https://www.youtube.com/embed/agQAYZEa5TQ"
                  title="Heavie House Chapter One: Legendary Beginnings"
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen={true}
                />
              </React.Fragment>
            ) : undefined}
          </div>
          {/* </AnimationOnScroll> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionTwo;

// /* Common Imports */
// import React from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import { useRef, useState, useEffect } from "react";
// import { motion, useAnimation } from "framer-motion";

// /* Component Imports */
// import { Typography, Button } from "@material-ui/core";

// /* Data Imports */
// import AppConfig from "../../../AppConfig";

// const useStyles = makeStyles((theme) => ({
//   megaContainer: {
//     /* XXS breakpoint */
//     [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
//       padding: "0rem 0.5rem 1rem 0.5rem",
//     },
//     [theme.breakpoints.only("xs")]: {
//       padding: "0rem 1.5rem 1rem 1.5rem",
//     },
//     [theme.breakpoints.only("sm")]: {
//       padding: "0rem 3rem 1rem 3rem",
//     },
//     [theme.breakpoints.only("md")]: {
//       padding: "0rem 4rem 1rem 4rem",
//     },
//     [theme.breakpoints.only("lg")]: {
//       padding: "0rem 4rem 1rem 4rem",
//     },
//     /* XLG breakpoint */
//     [theme.breakpoints.up(theme.breakpoints.values.lg + 48)]: {
//       padding: "0rem 5rem 1rem 5rem",
//     },
//     /* Full HD breakpoint */
//     [theme.breakpoints.up("xl")]: {
//       padding: "0rem 23rem 1rem 23rem",
//     },
//     /* XXL breakpoint */
//     [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
//       padding: "0rem 36rem 1rem 36rem",
//     },
//     /* XXXL breakpoint */
//     [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
//       padding: "0rem 72rem 1rem 72rem",
//     },
//     background: "#fafafa",
//     margin: "0rem 0rem 6rem 0rem",
//     [theme.breakpoints.down("xs")]: {
//       margin: "0rem 0rem 3rem 0rem",
//     },
//   },
//   sectionContainer: {
//     display: "flex",
//     flexDirection: "row",
//     [theme.breakpoints.down("xs")]: {
//       flexDirection: "column",
//       justifyContent: "center",
//     },
//     justifyContent: "space-between",
//     alignItems: "center",
//     padding: "2rem 0rem 2rem 0rem",
//     width: "100%",
//   },
//   text: {
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "flex-start",
//     width: "100%",
//   },
//   heading: {
//     width: "100%",
//     color: "#74378E",
//     fontWeight: "400",
//     fontSize: "2.5rem",
//     lineHeight: "3rem",
//     margin: "0rem 0rem 0.5rem 0rem",
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "2.125rem",
//       lineHeight: "3.1875rem",
//     },
//     [theme.breakpoints.down("xs")]: {
//       fontSize: "1.75rem",
//       lineHeight: "2rem",
//     },
//     background: "-webkit-linear-gradient(180deg, #74378E 0%, #502761 100%)",
//     "-webkit-background-clip": "text",
//     "-webkit-text-fillColor": "transparent",
//   },
//   subHeading: {
//     margin: "0.5rem 0rem 0.5rem 0rem",
//     width: "100%",
//     color: "#202020",
//     fontWeight: "400",
//     fontSize: "1.125rem",
//     lineHeight: "2rem",
//     [theme.breakpoints.down("sm")]: {
//       fontSize: "1rem",
//       lineHeight: "1.7rem",
//     },
//   },
//   buttons: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "flex-start",
//     alignItems: "center",
//     width: "100%",
//     margin: "0.5rem 0rem 0rem 0rem",
//   },
//   primaryButton: {
//     paddingLeft: "0rem",
//     color: "#74378E",
//     background: "-webkit-linear-gradient(180deg, #74378E 0%, #502761 100%)",
//     "-webkit-background-clip": "text",
//     "-webkit-text-fillColor": "transparent",
//     borderRadius: "0rem",
//     "&:hover": {
//       background: "-webkit-linear-gradient(180deg, #74378E 0%, #502761 100%)",
//       "-webkit-background-clip": "text",
//       "-webkit-text-fillColor": "transparent",
//     },
//     fontSize: "1.25rem",
//     lineHeight: "1.5rem",
//     fontWeight: 500,
//     textTransform: "Capitalize",
//   },
// }));

// const SectionTwo = () => {
//   const classes = useStyles();
//   const controls = useAnimation();
//   const rootRef = useRef();
//   const onScreen = useOnScreen(rootRef);

//   React.useEffect(() => {
//     if (onScreen) {
//       controls.start({
//         x: 0,
//         opacity: 1,
//         transition: {
//           duration: 1,
//           ease: "easeOut",
//         },
//       });
//     }
//   }, [onScreen, controls]);

//   function useOnScreen(ref, rootMargin = "0px") {
//     const [isIntersecting, setIntersecting] = useState(false);

//     useEffect(() => {
//       const observer = new IntersectionObserver(
//         ([entry]) => {
//           setIntersecting(entry.isIntersecting);
//         },
//         {
//           rootMargin,
//         }
//       );
//       if (ref.current) {
//         observer.observe(ref.current);
//       }
//       return () => {
//         observer.unobserve(ref.current);
//       };
//     }, []);

//     return isIntersecting;
//   }

//   return (
//     <React.Fragment>
//       <div className={classes.megaContainer}>
//         <div className={classes.sectionContainer}>
//           <motion.div
//             className={classes.text}
//             ref={rootRef}
//             initial={{ opacity: 0, x: -200 }}
//             animate={controls}
//           >
//             <Typography variant="h3" component="h3" className={classes.heading}>
//               About us
//             </Typography>
//             <Typography
//               variant="body1"
//               component="span"
//               className={classes.subHeading}
//             >
//               Sentient was created to develop all aspects real estate segment in a
//               way that will inspire the coming generations to create sustainable
//               and functional structures that nuture the earth and push the
//               evolution of human society in a positive direction.
//             </Typography>
//             <div className={classes.buttons}>
//               <Button
//                 href="/about-us"
//                 title="Know More"
//                 rel="noopener"
//                 className={classes.primaryButton}
//                 endIcon={
//                   <img
//                     src="/images/icons/misc/arrow_right_purple.svg"
//                     alt={AppConfig.imageAltText}
//                     width={20}
//                     height={20}
//                     loading="eager"
//                     referrerPolicy="no-referrer"
//
//                   />
//                 }
//               >
//                 Know More
//               </Button>
//             </div>
//           </motion.div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// };

// export default SectionTwo;
