/* Common Imports */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

/* Component Imports */
import { Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  megaContainer: {
    margin: "3rem 0rem 3rem 0rem",
    /* XXS breakpoint */
    [theme.breakpoints.down(theme.breakpoints.values.xs + 280)]: {
      padding: "0rem 0.5rem 1rem 0.5rem",
    },
    [theme.breakpoints.only("xs")]: {
      padding: "0rem 1.5rem 1rem 1.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      padding: "0rem 3rem 1rem 3rem",
    },
    [theme.breakpoints.only("md")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    [theme.breakpoints.only("lg")]: {
      padding: "0rem 4rem 1rem 4rem",
    },
    /* XLG breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.lg + 48)]: {
      padding: "0rem 5rem 1rem 5rem",
    },
    /* Full HD breakpoint */
    [theme.breakpoints.up("xl")]: {
      padding: "0rem 23rem 1rem 23rem",
    },
    /* XXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 640)]: {
      padding: "0rem 36rem 1rem 36rem",
    },
    /* XXXL breakpoint */
    [theme.breakpoints.up(theme.breakpoints.values.xl + 1920)]: {
      padding: "0rem 72rem 1rem 72rem",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0rem 0rem 3rem 0rem",
    },
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    // height: "calc(100vh - 4rem)",
  },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "Center",
    width: "100%",
  },
  heading: {
    width: "100%",
    color: "#0FCA56",
    // background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    // WebkitBackgroundClip: "text",
    // WebkitTextFillColor: "transparent",
    fontWeight: "700",
    fontSize: "4rem",
    lineHeight: "4.8rem",
    margin: "0rem 0rem 1rem 0rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.125rem",
      lineHeight: "3.1875rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "2.375rem",
      lineHeight: "2.85rem",
    },
  },
  subHeading: {
    margin: "0rem 0rem 1rem 0rem",
    width: "100%",
    color: "#000000",
    fontWeight: "400",
    fontSize: "1.5rem",
    lineHeight: "1.75rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      lineHeight: "1.7rem",
    },
  },
  buttons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "50%",
    margin: "1.4rem 0rem 0rem 0rem",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      margin: "0.5rem 0rem 0rem 0rem",
    },
  },
  primaryButton: {
    background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
    color: "#FFFFFF",
    borderWidth: "0px",
    borderRadius: "0rem",
    fontSize: "1rem",
    fontWeight: "500",
    textTransform: "capitalize",
    padding: "0.75rem 1rem",
    "&:hover": {
      background: "linear-gradient(to right, #0FCA56 0%, #08A945 100%)",
      color: "#FFFFFF",
      textDecoration: "underline",
      textUnderlineOffset: "3px",
    },
  },
}));

const SectionOneNew = () => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className={classes.megaContainer}>
        <div className={classes.sectionContainer}>
          <div className={classes.text}>
            <Typography variant="h2" component="h2" className={classes.heading}>
              Legendary Beginnings
            </Typography>
            <Typography
              variant="body2"
              component="span"
              className={classes.subHeading}
            >
              The Garden of Eden to transform your reality
            </Typography>
          </div>
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              href={`/eden`}
              title="Heavie Eden"
              rel="noopener"
              className={classes.primaryButton}
            >
              View Heavie Eden
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SectionOneNew;
